// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NoResponsePage.css */
.no-response-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.message-box {
    text-align: center;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.message {
    font-size: 1.25rem;
    color: #7f8c8d;
}`, "",{"version":3,"sources":["webpack://./src/pages/NoResponsePage.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":["/* NoResponsePage.css */\n.no-response-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f4f4f4;\n}\n\n.message-box {\n    text-align: center;\n    background-color: #ffffff;\n    padding: 40px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.title {\n    font-size: 2.5rem;\n    color: #2c3e50;\n    margin-bottom: 20px;\n}\n\n.message {\n    font-size: 1.25rem;\n    color: #7f8c8d;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

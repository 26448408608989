// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
    line-height: 1;
    padding: rem(8px) rem(12px);
    border-radius: 2%;
    text-decoration: none;
    color: white;
    font-size: 15;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,2BAA2B;IAC3B,iBAAiB;IACjB,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".link {\n    line-height: 1;\n    padding: rem(8px) rem(12px);\n    border-radius: 2%;\n    text-decoration: none;\n    color: white;\n    font-size: 15;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    /* Adjust this value to your desired width */
    height: 300px;
    /* Set the same value as width to make it square */
}

.fixed-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/pages/home.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,4CAA4C;IAC5C,aAAa;IACb,kDAAkD;AACtD;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;AACjB","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    width: 300px;\n    /* Adjust this value to your desired width */\n    height: 300px;\n    /* Set the same value as width to make it square */\n}\n\n.fixed-icon {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
